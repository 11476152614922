import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelperToolsService } from '../services/helper-tools.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private helperTool: HelperToolsService,
    private coockieService: CookieService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (
          err['error'] &&
          err['error']['error_message'].includes('The Token has expired on')
        ) {
          this.helperTool.showAlertWithTimer(
            '',
            'انتهت الجلسة. من فضلك سجل الدخول مرة اخري',
            'warning'
          );
          this.coockieService.delete('pm_token');
          this.router.navigate(['/login']);
          // this.helperTool
          //   .showSignInAlert('انتهت الجلسة.', 'من فضلك سجل الدخول مرة اخري')
          //   .then((__) => {})
          //   .catch((err) => {});
        }
        return throwError(err);
      })
    );
    // throw new Error("Method not implemented.");
  }
}
