import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent implements OnInit, AfterViewInit {
  @ViewChild('appSidebar') appSidebar: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;

  hideSidebar: boolean;
  iscollapsed = false;
  isSidebar_sm = false;
  isSidebar_lg = false;

  public config: any = {};

  constructor(private configService: ConfigService) {}

  ngOnInit() {
    this.config = this.configService.templateConf;
    setTimeout(() => {
      if (this.config.layout.sidebar.size === 'sidebar-lg') {
        this.isSidebar_sm = false;
        this.isSidebar_lg = true;
      } else if (this.config.layout.sidebar.size === 'sidebar-sm') {
        this.isSidebar_sm = true;
        this.isSidebar_lg = false;
      } else {
        this.isSidebar_sm = false;
        this.isSidebar_lg = false;
      }
      this.iscollapsed = this.config.layout.sidebar.collapsed;
    }, 0);
  }

  ngAfterViewInit() {}

  toggleHideSidebar($event: boolean): void {
    setTimeout(() => {
      this.hideSidebar = $event;
    }, 0);
  }
}
