<div class="genral-filter">
    <div class="card-header">
        <h4> الفلتر العام </h4>
    </div>

    <form [formGroup]="form" (submit)="onSubmit()">
        <!-- <div class="position-relative">
            <a role="button" class="d-block filter-item" bsDaterangepicker [bsConfig]="{ displayMonths :1}"> الفترة</a>
        </div> -->
        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" [ngClass]="{'active': form.get('deptId').value?.length}" dropdownToggle aria-controls="dropdown-autoclose1">
                القطاعات</a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <!-- <div class="custom-control custom-checkbox mb-2" *ngFor="let dep of departments">
                    <input type="checkbox" class="custom-control-input" [id]="dep.lookupMainName + dep.lookupId" [value]="dep.lookupId" formControlName="deptId">
                    <label class="custom-control-label" [for]="dep.lookupMainName + dep.lookupId">{{dep.lookupMainName}}</label>
                </div> -->
                <checkbox-group formControlName="deptId">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let dep of departments">
                        <checkbox [value]="dep.lookupId">{{dep.lookupMainName}}</checkbox>
                    </div>
                </checkbox-group>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" [ngClass]="{'active': form.get('regId').value}" dropdownToggle aria-controls="dropdown-autoclose1">
                المناطق </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <checkbox-group formControlName="regId">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let region of regions">
                        <checkbox [value]="region.lookupId">{{region.lookupMainName}}</checkbox>
                    </div>
                </checkbox-group>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" [ngClass]="{'active': form.get('initiativeId').value}" dropdownToggle aria-controls="dropdown-autoclose1"> المبادرات </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <checkbox-group formControlName="initiativeId">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let initiative of initiatives">
                        <checkbox [value]="initiative.lookupId">{{initiative.lookupMainName}}</checkbox>
                    </div>
                </checkbox-group>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" [ngClass]="{'active': form.get('workAreaId').value}" dropdownToggle aria-controls="dropdown-autoclose1">
                مجال العمل </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <checkbox-group formControlName="workAreaId">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let workField of workFields">
                        <checkbox [value]="workField.lookupId">{{workField.lookupMainName}}</checkbox>
                    </div>
                </checkbox-group>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" [ngClass]="{'active': form.get('contractStatusId').value}" dropdownToggle aria-controls="dropdown-autoclose1"> الحالة التعاقدية </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <checkbox-group formControlName="contractStatusId">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let contract of contractStatus">
                        <checkbox [value]="contract.lookupId">{{contract.lookupMainName}}</checkbox>
                    </div>
                </checkbox-group>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" [ngClass]="{'active': form.get('executionStatusId').value}" dropdownToggle aria-controls="dropdown-autoclose1"> حالة التنفيذ </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <checkbox-group formControlName="executionStatusId">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let execution of executionStatus">
                        <checkbox [value]="execution.lookupId">{{execution.lookupMainName}}</checkbox>
                    </div>
                </checkbox-group>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" [ngClass]="{'active': form.get('projectStatusId').value}" dropdownToggle aria-controls="dropdown-autoclose1"> الحالة الراهنة </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <checkbox-group formControlName="projectStatusId">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let current of currentStatus">
                        <checkbox [value]="current.lookupId">{{current.lookupMainName}}</checkbox>
                    </div>
                </checkbox-group>
            </ul>
        </div>

        <div class="btn_group text-center mt-4">
            <button type="button" class="btn btn-danger ml-2" (click)="bsModalRef.hide()"> إلغاء </button>
            <div class=" d-inline-block"> <button type="submit" class="btn btn-success"> حفظ
                </button> </div>
        </div>
    </form>
</div>