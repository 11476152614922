import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { ConfigService } from '../../services/config.service';
var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  expandedSubmenu: boolean = false;
  expandedSubmenu1: boolean = false;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};

  constructor(
    private renderer: Renderer2,
    private configService: ConfigService,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngOnInit() {
    this.config = this.configService.templateConf;

    console.log('Check Expanded !!!!', this.expanded);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-left-circle-outline'
          );
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-right-circle-outline'
          );
          this.nav_collapsed_open = true;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-left-circle-outline'
          );
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-right-circle-outline'
          );
          this.nav_collapsed_open = false;
        }
      }
    }, 0);
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
    console.log('Check Expanded !!!!', this.expanded);
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  logout() {
    this.cookieService.delete('pm_token');
    window.location.href = 'login';
    // this.authService
    //   .logOut({ username: environment.USER_DATA['username'] })
    //   .subscribe(
    //     (data) => {},
    //     (err) => {
    //       if (err['status'] == 200) {
    //         this.cookieService.delete('user_data');
    //         this.router.navigate(['login']);
    //       }
    //       console.error(err);
    //     }
    //   );
  }

  toggleSubmenu() {
    this.expandedSubmenu = !this.expandedSubmenu;
  }
  toggleSubmenu1() {
    this.expandedSubmenu1 = !this.expandedSubmenu1;
  }
}
