<div class="overlay"></div>
<div class="text-right">
    <button type="button" class="navbar-toggle d-lg-none text-white" data-toggle="collapse" (click)="toggleSidebar()">
    <i class="mdi mdi-menu"></i>
  </button>
</div>
<div class="d-flex align-items-center justify-content-between position-relative">
    <div class="right-side text-right" dropdown>
        <a id="button-basic" href="javascript:void(0)" dropdownToggle class="dropdown-toggle menu-btn">
            <i class="mdi mdi-dots-grid"></i>
        </a>
        <div class="main-menu" id="dropdown-basic" *dropdownMenu>
            <div class="menu-card text-right">
                <ul>
                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/strategy.png" />
                            <span class="d-block"> التخطيط الاستراتيجي </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/wallet.png" />
                            <span class="d-block"> إدارة المحافظ </span>
                        </a>
                    </li>

                    <li class="active">
                        <a href="#">
                            <img src="./assets/images/icons/project-management.png" />
                            <span class="d-block"> إدارة المشاريع</span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/gavel.png" />
                            <span class="d-block"> إدارة المناقصات </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/contract.png" />
                            <span class="d-block"> إعداد الكراسات </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/finance.png" />
                            <span class="d-block"> المالية </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/human-resources.png" />
                            <span class="d-block"> الموارد البشرية </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/newspaper.png" />
                            <span class="d-block"> الاعلام </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/file.png" />
                            <span class="d-block"> إدارة الوثائق </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/growth.png" />
                            <span class="d-block"> هندسة القيمة </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/quality.png" />
                            <span class="d-block"> هندسة الجودة </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/documents.png" />
                            <span class="d-block"> المراجعة الداخلية </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/teamwork.png" />
                            <span class="d-block"> الشركاء </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/group.png" />
                            <span class="d-block"> الخبراء </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/consultant-services.png" />
                            <span class="d-block"> الاستشاريون</span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/worker.png" />
                            <span class="d-block"> المقاولون </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/truck.png" />
                            <span class="d-block"> الموردون </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/essay.png" />
                            <span class="d-block"> التقارير </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/webpage.png" />
                            <span class="d-block"> لوحة المعلومات </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/book.png" />
                            <span class="d-block"> المكتبة </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/account.png" />
                            <span class="d-block"> الحسابات الشخصية </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/notebook.png" />
                            <span class="d-block"> الأجندة </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/customize.png" />
                            <span class="d-block"> إدارة المستخدمين </span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <img src="./assets/images/icons/cog.png" />
                            <span class="d-block"> إعدادت النظام </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <h3>نظام تتبع وعرض بيانات المشاريع</h3>
        <ul class="navigator">
            <!-- <li> <span> وزارة البيئة والمياة والزراعة </span> </li> -->
            <li> <span> الرئيسية </span> </li>
            <li> <span class="mdi mdi-arrow-left-drop-circle-outline"> </span> </li>
            <li> <a role="button" [routerLink]="'/' + current_route.split('/')[1]"> {{
              findInDictionary(current_route.split('/')[1]) }} </a> </li>
            <li *ngIf="current_route.split('/')[2]"> <span class="mdi mdi-arrow-left-drop-circle-outline"> </span> </li>
            <!-- <li *ngIf="current_route.split('/')[3]"> <a role="button"> مشروع {{ project.projectName }} </a> </li> -->
            <li *ngIf="current_route.split('/')[2]"> <a role="button" [routerLink]="current_route"> {{ findInDictionary(current_route.split('/')[2]) }} </a> </li>
        </ul>
    </div>

    <div class="left-side">
        <ul>
            <!-- <li>
                <a class="text-white" href="#"> <i class="mdi mdi-bell-ring-outline"></i> </a>
            </li> -->
            <li>
                <a role="button" class="text-white">
                    <img src="./assets/images/user.png" /> <strong> admin </strong>
                </a>
            </li>
        </ul>
    </div>
</div>