import { Routes, RouterModule } from '@angular/router';

export const Full_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('../../pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('../../pages/projects/projects.module').then(
        (m) => m.ProjectsModule
      ),
  },
  {
    path: 'system',
    loadChildren: () =>
      import('../../pages/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../../pages/main-reports/main-reports.module').then(
        (m) => m.MainReportsModule
      ),
  },
  {
    path: 'projectdashboard',
    loadChildren: () =>
      import('../../pages/project-dashboard/project-dashboard.module').then(
        (m) => m.ProjectDashboardModule
      ),
  },
  {
    path: 'collected-dashboard',
    loadChildren: () =>
      import(
        '../../pages/project-dashboard-collected/project-dashboard-collected.module'
      ).then((m) => m.ProjectDashboardCollectedModule),
  },
];
