import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentComponent } from './layout/content/content.component';
import { FullComponent } from './layout/full/full.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SidebarDirective } from './shared/directives/sidebar.directive';
import { SidebarToggleDirective } from './shared/directives/sidebartoggle.directive';
import { FilterComponent } from './shared/components/filter/filter.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './shared/auth/auth.guard';
import { AuthService } from './shared/auth/auth.service';
import { LoginGuard } from './shared/auth/login.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxGroupComponent } from './shared/components/filter/checkbox-group.component';
import { CheckboxComponent } from './shared/components/filter/checkbox.component';
import { ErrorHandlerInterceptor } from './shared/interceptors/error-handler-interceptor';
import {
  SwiperConfigInterface,
  SwiperModule,
  SWIPER_CONFIG,
} from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    FullComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    SidebarDirective,
    SidebarToggleDirective,
    FilterComponent,
    ClickOutsideDirective,
    CheckboxGroupComponent,
    CheckboxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    ReactiveFormsModule,
    SwiperModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    CookieService,
    AuthService,
    AuthGuard,
    LoginGuard,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
