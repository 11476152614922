import {
  AfterViewChecked,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';
import { filter, pairwise } from 'rxjs/operators';
import { ProjectsService } from '../../services/projects.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Output() directionEvent = new EventEmitter<Object>();
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  Notificationcount = 0;
  user_data = { username: '' };
  // currentUrl = { name: '', url: '' };
  // secondUrl: string = '';

  dictionary = {
    details: 'التفاصيل',
    projects: 'المشاريع',
    settings: 'إعدادات النظام',
    dashboard: 'لوحة التحكم',
    project: 'المشاريع',
    aggregate: 'التجميعية',
    create: 'اضافة مشروع',
    current: 'الوضع الحالي للمشاريع',
    timetable: 'الجدول الزمني والإنجاز والمنصرف',
    'collected-dashboard': 'لوحة العرض المجمَعة',
    projectdashboard: 'لوحات العرض المنفردة',
    general: 'الوضع العام',
    achievement: 'تطور الإنجاز',
    itemsdata: 'بيانات البنود',
    financial: 'البيانات المالية'
  };

  current_route: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private projectservice: ProjectsService
  ) {
    this.current_route = this.router.url;
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((events: NavigationEnd[]) => {
        this.current_route = events[1].urlAfterRedirects;
      });
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  ngOnInit(): void {}

  findInDictionary(value) {
    return this.dictionary[value];
  }
}
