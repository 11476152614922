import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  httpOptions;
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: cookieService.get('pm_token'),
      }),
    };
  }

  signin(sentData) {
    const URL = `${environment.BASE_URL}/login`;
    return this.http.post(URL, sentData);
  }

  logOut(sentData) {
    const URL = `${environment.BASE_URL}/user/logout?username=${sentData['username']}`;
    return this.http.post(URL, {}, this.httpOptions);
  }
}
