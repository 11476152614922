import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectsService } from '../../services/projects.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  departments = [] as any;
  regions = [] as any;
  contractStatus = [] as any;
  currentStatus = [] as any;
  initiatives = [] as any;
  executionStatus = [] as any;
  workFields = [] as any;

  public form = new FormGroup({
    contractStatusId: new FormControl(''),
    deptId: new FormControl(''),
    executionStatusId: new FormControl(''),
    initiativeId: new FormControl(''),
    projectStatusId: new FormControl(''),
    regId: new FormControl(''),
    workAreaId: new FormControl(''),
  });
  filterData = {} as any;
  constructor(
    public bsModalRef: BsModalRef,
    private projectService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.getRegions();
    this.getDepartments();
    this.getInitiative();
    this.getContractStatus();
    this.getCurrentStatus();
    this.getExecutionStatus();
    this.getWorkFields();
    for (const key in this.filterData) {
      if (typeof this.filterData[key] == 'string' && this.filterData[key]) {
        this.filterData[key] = this.filterData[key].split(',').map(Number);
      }
    }
    this.form.patchValue(this.filterData);
  }

  getDepartments() {
    this.projectService.getLookupById(1).subscribe(
      (data) => {
        this.departments = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getRegions() {
    this.projectService.getLookupById(2).subscribe(
      (data) => {
        this.regions = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getInitiative() {
    this.projectService.getLookupById(3).subscribe(
      (data) => {
        this.initiatives = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getWorkFields() {
    this.projectService.getLookupById(4).subscribe(
      (data) => {
        this.workFields = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getContractStatus() {
    this.projectService.getLookupById(5).subscribe(
      (data) => {
        this.contractStatus = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getExecutionStatus() {
    this.projectService.getLookupById(6).subscribe(
      (data) => {
        this.executionStatus = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getCurrentStatus() {
    this.projectService.getLookupById(7).subscribe(
      (data) => {
        this.currentStatus = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onSubmit() {
    this.projectService.emitFilterData(this.form.value);
    this.bsModalRef.hide();
  }
}
