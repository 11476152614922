import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root',
})
export class HelperToolsService {
  constructor(
    // private SpinnerService: NgxSpinnerService,\01'];
    private http: HttpClient
  ) {}

  showAlert(title, message, swlType) {
    swal.fire({
      title: title,
      text: message,
      icon: swlType,
      confirmButtonText: 'تأكيد',
    });
  }

  showAlertWithTimer(title, message, swlType) {
    // swal.fire(keys[title], keys[message], swlType);
    swal.fire({
      title: title,
      text: message,
      icon: swlType,
      timer: 3000,
      showConfirmButton: false,
    });
  }

  showConfirmAlert(title, text) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'تأكيد',
          cancelButtonText: 'إلغاء',
        })
        .then((result) => {
          if (result.value) {
            resolve('done');
          } else {
            reject('error');
          }
        });
    });
  }
  showSignInAlert(title, text) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3283f6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'تسجيل الدخول',
          cancelButtonText: 'إلغاء',
        })
        .then((result) => {
          if (result.value) {
            resolve('done');
          } else {
            reject('error');
          }
        });
    });
  }
}
