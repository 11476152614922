import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginGuard implements CanActivate {
  newToken;
  constructor(private cookieService: CookieService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve, reject) => {
      let token = this.cookieService.get('pm_token');
      if (token) {
        this.router.navigate(['/projects']);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}
