// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  USER_DATA: {} as any,
  BASE_URL: 'https://pm-backend-p2t2qinwsa-uc.a.run.app',
  firebaseConfig: {
    apiKey: 'AIzaSyDINtx_t_nkKuiNJQY0_OR78rTTtF5P3nU',
    authDomain: 'rfp-module.firebaseapp.com',
    databaseURL: 'https://rfp-module-default-rtdb.firebaseio.com',
    projectId: 'rfp-module',
    storageBucket: 'rfp-module.appspot.com',
    messagingSenderId: '978685778215',
    appId: '1:978685778215:web:e1c297d26eb3768733b102',
    measurementId: 'G-FSFXP5RTHV',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
