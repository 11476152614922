<div class="options">
    <a class="nav-close d-block d-md-block d-lg-none d-xl-none text-danger" id="sidebarClose" href="javascript:;">
        <i class="mdi mdi-close"> </i>
    </a>
</div>
<div class="sidebar-main">
    <div class="logo text-center">
        <!-- <img src="./assets/images/reef-sm.png"> -->
        <img src="./assets/images/logo2.png">
        <img src="./assets/images/small-logo.png">
        <!-- <strong class="text-primary d-block"> وزارة البيئة والمياه والزراعة </strong>
        <span class="d-block text-primary" style="font-size: 12px;"> Ministry of environment water & agriculture </span>
        <strong class="text-secondary"> وكالة المياه </strong> -->
    </div>
    <div class="links-container">
        <ul class="pt-3 pb-3">
            <!-- <li routerLinkActive="active">
                <a routerLink="/home"> <i class="mdi mdi-view-dashboard-outline"></i> لوحة العرض </a>
            </li> -->
            <li routerLinkActive="active">
                <a routerLink="/projects"> <i class="mdi mdi-view-week"></i> إدارة المشروعات </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="dashboard/project"> <i class="mdi mdi-view-dashboard-outline"></i> لوحة المعلومات للمشاريع </a>
            </li>
            <li routerLinkActive="active">
                <a routerLink="/dashboard/aggregate"> <i class="mdi mdi-view-dashboard-outline"></i> لوحة المعلومات التجميعية </a>
            </li>
            <!-- <li routerLinkActive="active">
                <a routerLink="/reports"> <i class="mdi mdi-file-check"></i> التقارير </a>
            </li> -->
            <li routerLinkActive="active">
                <a routerLink="/system/settings"> <i class="mdi mdi-cog"></i> ثوابت النظام </a>
            </li>

            <li routerLinkActive="active">
                <a role="button" (click)="toggleSubmenu()">
                    <i class="mdi mdi-view-dashboard-outline"></i> لوحات العرض المنفردة
                    <i class="fa fa-caret-left mr-3" *ngIf="!expandedSubmenu"></i>
                    <i class="fa fa-caret-down mr-3" *ngIf="expandedSubmenu"></i>
                </a>
                <ul class="sub-menu" [ngStyle]="{display: expandedSubmenu== true ? 'block' : 'none'}">
                    <li routerLinkActive="active">
                        <a routerLink="/projectdashboard/general"> <i class="fa fa-circle-thin"></i> الوضع العام </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/projectdashboard/achievement"> <i class="fa fa-circle-thin"></i> تطور الانجاز
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/projectdashboard/itemsdata"> <i class="fa fa-circle-thin"></i> بيانات البنود
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/projectdashboard/achievementDetails"> <i class="fa fa-circle-thin"></i>  الانجاز المخطط والمتحقق
                        </a>
                    </li>

                    

                </ul>

            </li>


            <li routerLinkActive="active">
                <a role="button" (click)="toggleSubmenu1()">
                    <i class="mdi mdi-view-dashboard-outline"></i> لوحات العرض المجمعة
                    <i class="fa fa-caret-left mr-3" *ngIf="!expandedSubmenu1"></i>
                    <i class="fa fa-caret-down mr-3" *ngIf="expandedSubmenu1"></i>
                </a>
                <ul class="sub-menu" [ngStyle]="{display: expandedSubmenu1== true ? 'block' : 'none'}">
                    <li routerLinkActive="active">
                        <a routerLink="/collected-dashboard/current"> <i class="fa fa-circle-thin"></i> الوضع الحالى للمشاريع </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/collected-dashboard/timetable"> <i class="fa fa-circle-thin"></i> الجدول الزمنى
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/collected-dashboard/financial"> <i class="fa fa-circle-thin"></i> البيانات المالية </a>
                    </li>
                </ul>

            </li>

        </ul>
        <div class="d-flex align-items-center justify-content-between logout">
            <a href="javascript:void(0)" (click)="logout()"> <i class="mdi mdi-logout text-danger"></i> تسجيل الخروج
            </a>
            <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block text-yellow" id="sidebarToggle" href="javascript:;">
                <i #toggleIcon appSidebarToggle class="mdi toggle-icon" data-toggle="expanded"></i>
            </a>
        </div>
    </div>

    <div class="small-footer">
        <p> Powered by </p>
        <img src="./assets/images/evara-logo-1.jpeg">
    </div>
</div>