import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  httpOptions;
  private emitChange = new Subject<any>();
  filterDataChanged$ = this.emitChange.asObservable();
  private emitNewChange = new Subject<any>();
  newChangeEmitted$ = this.emitNewChange.asObservable();
  private emitItemsChange = new Subject<any>();
  itemsChagned$ = this.emitItemsChange.asObservable();

  private emitName = new Subject<any>();
  nameChagned$ = this.emitName.asObservable();
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: cookieService.get('pm_token'),
      }),
    };
  }

  emitFilterData(change: any) {
    this.emitChange.next(change);
  }

  emitAnyChange(change: any) {
    this.emitNewChange.next(change);
  }

  emitProjectItems(change: any) {
    this.emitItemsChange.next(change);
  }

  emitProjectName(change: any) {
    this.emitName.next(change);
  }

  getAllProjects(sentData) {
    for (const key in sentData) {
      if (typeof sentData[key] == 'object' && sentData[key].length) {
        sentData[key] = sentData[key].join(',');
      } else if (
        typeof sentData[key] == 'object' &&
        sentData[key].length == 0
      ) {
        sentData[key] = '';
      }
    }
    console.log(sentData);
    const URL = `${environment.BASE_URL}/projects/SearchForProjects`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  getLookupById(LookupType) {
    const URL = `${environment.BASE_URL}/lookups/getallobjects`;
    return this.http.get(URL, {
      params: { LookupType },
      headers: this.httpOptions.headers,
    });
  }

  getProjectById(ProjectId) {
    const URL = `${environment.BASE_URL}/projects/getdetails`;
    return this.http.get(URL, {
      params: { ProjectId },
      headers: this.httpOptions.headers,
    });
  }

  getProjectItems(ProjectId) {
    const URL = `${environment.BASE_URL}/projectitem/getProjectItems`;
    return this.http.get(URL, {
      params: { ProjectId },
      headers: this.httpOptions.headers,
    });
  }

  addProject(sentData) {
    const URL = `${environment.BASE_URL}/projects/AddProject`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  editProject(sentData) {
    const URL = `${environment.BASE_URL}/projects/SaveMainInfo?ProjectId=${sentData['projectId']}`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  deleteProject(ProjectId) {
    const URL = `${environment.BASE_URL}/projects/deletProject`;
    return this.http.get(URL, {
      params: { ProjectId },
      headers: this.httpOptions.headers,
    });
  }

  deleteItem(ProjectItemId) {
    const URL = `${environment.BASE_URL}/projectitem/deletProjectItem`;
    return this.http.get(URL, {
      params: { ProjectItemId },
      headers: this.httpOptions.headers,
    });
  }

  addProjectItem(sentData) {
    const URL = `${environment.BASE_URL}/projectitem/saveItem`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  saveLookup(sentData) {
    const URL = `${environment.BASE_URL}/lookups/saveLookup`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  deleteLookup(lookupId) {
    const URL = `${environment.BASE_URL}/lookups/deleteLookup?LookupId=${lookupId}`;
    return this.http.get(URL, this.httpOptions);
  }

  savePlannedItem(sentData) {
    const URL = `${environment.BASE_URL}/projectitem/saveItemsPlan`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  getDashboardProjects(obj) {
    const URL = `${environment.BASE_URL}/projects/getProjectDashboard`;
    return this.http.get(URL, {
      params: obj,
      headers: this.httpOptions.headers,
    });
  }

  getDashBoardAggregate(sentData) {
    const URL = `${environment.BASE_URL}/kpis/SearchForProjects`;
    return this.http.post(URL, sentData, this.httpOptions);
  }

  copyProject(ProjectId) {
    const URL = `${environment.BASE_URL}/projects/copyproject`;
    return this.http.get(URL, {
      params: { ProjectId },
      headers: this.httpOptions.headers,
    });
  }
}
