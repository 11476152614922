import { HelperToolsService } from '../services/helper-tools.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  newToken;
  token;
  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    private helperTool: HelperToolsService
  ) {
    this.token = this.cookieService.get('pm_token');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.token) {
        this.helperTool
          .showSignInAlert('انتهت الجلسة.', 'من فضلك سجل الدخول مرة اخري')
          .then((__) => {
            this.router.navigate(['/login']);
          })
          .catch((err) => {});
        resolve(false);
      } else {
        environment.USER_DATA = this.cookieService.get('pm_token');
        // this.authService.emitUserData(
        //   JSON.parse(this.cookieService.get('user_data'))
        // );
        resolve(true);
        // this.authService.checkMe().subscribe(
        //   (data) => {
        //     environment.USER_DATA = data['data'];
        //     this.authService.emitUserData(data['data']);
        //     resolve(true);
        //   },
        //   (err) => {
        //     console.log(err);
        //     resolve(false);
        //   }
        // );
      }
    });
  }
}
